import React from "react";
import "./contactinfo.css";
import reviewUs from "./Assets/reviewUs.png";

function ContactInfo() {
  return (
    <div className="container-wrapper contact">
      <h1>Contact Information</h1>
      <div className="body-style contactBody">
        <div className="sub-content subcontent">
          <p className="p-style">
            Phone: <br></br>+65  8761 8755<br></br>
            +65 8783 0087 <br></br> <br></br> Contact@vibrantemployment.sg{" "}
            <br></br> <br></br> EA License: 24C2146 <br></br>
            <br></br>UEN 202403443N
          </p>
        </div>

        <div className="sub-content subcontent">
          <img src={reviewUs} className="review-img" alt="reviewUs" />
        </div>
      </div>
      <h3 className="h3-style">Service Hours</h3>
      <p className="opening-time">
          {" "}
          Monday to Sunday (Daily) <br></br>
          By Appointment Only <br></br>
          Contact Us to Schedule an Appointment
      </p>
    </div>
  );
}

export default ContactInfo;
