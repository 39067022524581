import React from "react";
import "./listings.css";

function Listings() {
  return (
    <div className="container-wrapper">
      <div className="canva-embed">
        <div
          style={{
            title: "presentation",
            position: "relative",
            width: "100%",
            height: "0",
            paddingTop: "56.25%",
            paddingBottom: "0",
            marginTop: "1.6em",
            marginBottom: "0.9em",
            overflow: "hidden",
            borderRadius: "8px",
            willChange: "transform"
          }}
        >
          <iframe
            loading="lazy"
            style={{
              title: "presentation",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              border: "none",
              padding: "0",
              margin: "0"
            }}
            src="https://www.canva.com/design/DAGRfCDSMZQ/LNWxPzjRsqXu2hvIWK1xIA/view?embed"
            allowFullScreen
            allow="fullscreen"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Listings;
