import Maps from "./maps";
import ContactInfo from "./contactinfo";

function Contactparent() {
  return (
    <>
      <ContactInfo />
    </>
  );
}

export default Contactparent;
